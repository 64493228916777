import React, { useEffect } from 'react'
import drag_dots_icon from '../../../../assets/icons/drag_dots.svg'
import video_icon from '../../../../assets/icons/video.svg'
import text_icon from '../../../../assets/icons/text.svg'
import button_icon from '../../../../assets/icons/button.svg'
import text_to_speech_icon from '../../../../assets/icons/text_to_speech.svg'
import image_icon from '../../../../assets/image_icon.svg'

import up_arrow_fill_icon from '../../../../assets/icons/up_arrow_fill.svg'
import down_arrow_fill_icon from '../../../../assets/icons/down_arrow_fill.svg'
import { observer } from 'mobx-react'
import { useStore } from '../../../../utility/hooks/UseStore'

const LayerPanel = () => {
    const mainStore = useStore();
    const store = mainStore.videoStore;

    const mainElementList = store.editorElements?.slice()?.sort((a, b) => {
        const order = { video: 1, audio: 2, default: 3 };
        const aType = order[a.type] || order.default;
        const bType = order[b.type] || order.default;
        if (aType !== bType) return aType - bType;
        return a.properties.created - b.properties.created;
    })
    const elementList = store.editorElements?.slice()?.sort((a, b) => {
        const order = { video: 1, audio: 2, default: 3 };
        const aType = order[a.type] || order.default;
        const bType = order[b.type] || order.default;
        if (aType !== bType) return aType - bType;
        return a.properties.created - b.properties.created;
    })?.reverse()

    useEffect(() => {}, [mainElementList])

    const isSelected = store.selectedElement?.id

    const manageList = (side, id) => {
        const copyArray = [...mainElementList]
        if (side === 'up') {
            const newArray = []
            copyArray.map((item, index) => {
                if (item.id !== id) {
                    if (index === 0) {
                        newArray.push(item)
                    } else if (copyArray[index - 1].id === id) {
                        const object = JSON.parse(JSON.stringify(copyArray[index - 1]))
                        object.properties.created = copyArray[index].properties.created
                        newArray.push(object)
                    } else {
                        newArray.push(item)
                    }
                } else {
                    const object = JSON.parse(JSON.stringify(copyArray[index + 1]))
                    object.properties.created = copyArray[index].properties.created
                    newArray.push(object)
                }
            })
            store.setEditorElements(newArray)
        } else if (side === 'down') {
            const newArray = []
            copyArray.map((item, index) => {
                if (item.id === id) {
                    const object = JSON.parse(JSON.stringify(copyArray[index - 1]))
                    object.properties.created = copyArray[index].properties.created
                    newArray.push(object)
                }
                else if (index !== copyArray.length - 1 && copyArray[Number(index + 1)].id === id) {
                    const object = JSON.parse(JSON.stringify(copyArray[index + 1])) 
                    object.properties.created = copyArray[index].properties.created
                    newArray.push(object)
                } else {
                    newArray.push(item)
                }
            })
            store.setEditorElements(newArray)
        }
    }
    
    return (
        <div className=''>
            <div className='p-[10px] 2xl:p-[20px] overflow-hidden'>
                {elementList && elementList.map((element, index) => {
                    return (
                        <div key={`element${index}`} className={`${isSelected === element.id ? 'border-[#5446d0]' : 'border-[#3B3B4F]'} p-[5px] 2xl:p-[16px] mb-[12px] border rounded-[4px] flex items-center justify-between`}>
                            <div className='flex items-center'>
                                <div className='w-[24px] h-[24px] bg-[#3B3B4F] rounded-[4px] flex justify-center items-center'>
                                    <img className={`${element.type !== 'image' && 'w-[15px]'}`} alt='' src={element.type === 'video' ? video_icon : element.type === "image" ? image_icon : element.type === "text" ? text_icon : element.type === 'audio' ? text_to_speech_icon : element.type === 'button' ? button_icon : null}></img>
                                </div>
                                <div className='ml-[5px] 2xl:ml-[10px]'>
                                    <p className='text-[14px] max-w-[110px] overflow-hidden text-ellipsis whitespace-nowrap truncate font-medium leading-[16.94px] text-[#ABABC0]'>{element.type === "video" ? "Video" : element.type === 'text' ? String(element.properties.text).length > 9 ? `${element.properties.text.substring(0, 9)}...` : element.properties.text ? element.properties.text : 'Text' : element.type === 'button' ? element.properties.text ? element.properties.text : 'Button' : element.type === 'audio' ? "Audio" : element.type === 'image' ? "Image" : null}</p>
                                </div>
                            </div>
                            {element.type !== 'video' && element.type !== 'audio' && (
                                <div className='flex items-center'>
                                    <div onClick={() => { if (index !== 0) { manageList('up', element.id) } }} className={`${index === 0 ? "cursor-not-allowed" : "cursor-pointer"} w-[24px] h-[24px] bg-[#3B3B4F] rounded-[4px] flex justify-center items-center`}>
                                        <img alt='' src={up_arrow_fill_icon} />
                                    </div>
                                    <div 
                                        onClick={() => { 
                                            if (elementList.length - 1 !== index && elementList[index + 1].type !== 'video' && elementList[index + 1].type !== 'audio') { 
                                                manageList('down', element.id) 
                                            } 
                                        }} 
                                        className={`${elementList.length - 1 === index || (elementList[index + 1] && (elementList[index + 1].type === 'video' || elementList[index + 1].type === 'audio')) ? "cursor-not-allowed" : "cursor-pointer"} w-[24px] h-[24px] bg-[#3B3B4F] rounded-[4px] flex justify-center items-center ml-[8px]`}
                                    >
                                        <img alt='' src={down_arrow_fill_icon} />
                                    </div>
                                </div>
                            )}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default observer(LayerPanel)
