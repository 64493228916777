export const fontFamilyJson = [
    // roboto
    {
        name: 'roboto',
        availableWeights: [100, 300, 400, 500, 700, 900],
        links: [
            {
                weight: 100,
                url: "https://videolocal.b-cdn.net/fonts/roboto/Roboto-Thin.ttf"
            },
            {
                weight: 300,
                url: "https://videolocal.b-cdn.net/fonts/roboto/Roboto-Light.ttf"
            },
            {
                weight: 400,
                url: "https://videolocal.b-cdn.net/fonts/roboto/Roboto-Regular.ttf"
            },
            {
                weight: 500,
                url: "https://videolocal.b-cdn.net/fonts/roboto/Roboto-Medium.ttf"
            },
            {
                weight: 700,
                url: "https://videolocal.b-cdn.net/fonts/roboto/Roboto-Bold.ttf"
            },
            {
                weight: 900,
                url: "https://videolocal.b-cdn.net/fonts/roboto/Roboto-Black.ttf"
            }
        ]
    },
    // arimo
    {
        name: 'arimo',
        availableWeights: [400, 500, 600, 700],
        links: [
            {
                weight: 400,
                url: "https://videolocal.b-cdn.net/fonts/arimo/Arimo-Regular.ttf"
            },
            {
                weight: 500,
                url: "https://videolocal.b-cdn.net/fonts/arimo/Arimo-Medium.ttf"
            },
            {
                weight: 600,
                url: "https://videolocal.b-cdn.net/fonts/arimo/Arimo-SemiBold.ttf"
            },
            {
                weight: 700,
                url: "https://videolocal.b-cdn.net/fonts/arimo/Arimo-Bold.ttf"
            }
        ]
    },
    // montserrat
    {
        name: "montserrat",
        availableWeights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
        links: [
            {
                weight: 100,
                url: "https://videolocal.b-cdn.net/fonts/montserrat/Montserrat-Thin.ttf"
            },
            {
                weight: 200,
                url: "https://videolocal.b-cdn.net/fonts/montserrat/Montserrat-ExtraLight.ttf"
            },
            {
                weight: 300,
                url: "https://videolocal.b-cdn.net/fonts/montserrat/Montserrat-Light.ttf"
            },
            {
                weight: 400,
                url: "https://videolocal.b-cdn.net/fonts/montserrat/Montserrat-Regular.ttf"
            },
            {
                weight: 500,
                url: "https://videolocal.b-cdn.net/fonts/montserrat/Montserrat-Medium.ttf"
            },
            {
                weight: 600,
                url: "https://videolocal.b-cdn.net/fonts/montserrat/Montserrat-SemiBold.ttf"
            },
            {
                weight: 700,
                url: "https://videolocal.b-cdn.net/fonts/montserrat/Montserrat-Bold.ttf"
            },
            {
                weight: 800,
                url: "https://videolocal.b-cdn.net/fonts/montserrat/Montserrat-ExtraBold.ttf"
            },
            {
                weight: 900,
                url: "https://videolocal.b-cdn.net/fonts/montserrat/Montserrat-Black.ttf"
            }
        ]
    },
    // open sans
    {
        name: 'open sans',
        availableWeights: [300, 400, 500, 600, 700, 800],
        links: [
            {
                weight: 300,
                url: "https://videolocal.b-cdn.net/fonts/open-sans/OpenSans-Light.ttf"
            },
            {
                weight: 400,
                url: "https://videolocal.b-cdn.net/fonts/open-sans/OpenSans-Regular.ttf"
            },
            {
                weight: 500,
                url: "https://videolocal.b-cdn.net/fonts/open-sans/OpenSans-Medium.ttf"
            },
            {
                weight: 600,
                url: "https://videolocal.b-cdn.net/fonts/open-sans/OpenSans-SemiBold.ttf"
            },
            {
                weight: 700,
                url: "https://videolocal.b-cdn.net/fonts/open-sans/OpenSans-Bold.ttf"
            },
            {
                weight: 800,
                url: "https://videolocal.b-cdn.net/fonts/open-sans/OpenSans-ExtraBold.ttf"
            }
        ]
    },
    // poppins
    {
        name: 'poppins',
        availableWeights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
        links: [
            {
                weight: 100,
                url: "https://videolocal.b-cdn.net/fonts/poppins/Poppins-Thin.ttf"
            },
            {
                weight: 200,
                url: "https://videolocal.b-cdn.net/fonts/poppins/Poppins-ExtraLight.ttf"
            },
            {
                weight: 300,
                url: "https://videolocal.b-cdn.net/fonts/poppins/Poppins-Light.ttf"
            },
            {
                weight: 400,
                url: "https://videolocal.b-cdn.net/fonts/poppins/Poppins-Regular.ttf"
            },
            {
                weight: 500,
                url: "https://videolocal.b-cdn.net/fonts/poppins/Poppins-Medium.ttf"
            },
            {
                weight: 600,
                url: "https://videolocal.b-cdn.net/fonts/poppins/Poppins-SemiBold.ttf"
            },
            {
                weight: 700,
                url: "https://videolocal.b-cdn.net/fonts/poppins/Poppins-Bold.ttf"
            },
            {
                weight: 800,
                url: "https://videolocal.b-cdn.net/fonts/poppins/Poppins-ExtraBold.ttf"
            },
            {
                weight: 900,
                url: "https://videolocal.b-cdn.net/fonts/poppins/Poppins-Black.ttf"
            }
        ]
    },
    // dm-sans
    {
        name: 'dm sans',
        availableWeights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
        links: [
            {
                weight: 100,
                url: "https://videolocal.b-cdn.net/fonts/dm-sans/DMSans-Thin.ttf"
            },
            {
                weight: 200,
                url: "https://videolocal.b-cdn.net/fonts/dm-sans/DMSans-ExtraLight.ttf"
            },
            {
                weight: 300,
                url: "https://videolocal.b-cdn.net/fonts/dm-sans/DMSans-Light.ttf"
            },
            {
                weight: 400,
                url: "https://videolocal.b-cdn.net/fonts/dm-sans/DMSans-Regular.ttf"
            },
            {
                weight: 500,
                url: "https://videolocal.b-cdn.net/fonts/dm-sans/DMSans-Medium.ttf"
            },
            {
                weight: 600,
                url: "https://videolocal.b-cdn.net/fonts/dm-sans/DMSans-SemiBold.ttf"
            },
            {
                weight: 700,
                url: "https://videolocal.b-cdn.net/fonts/dm-sans/DMSans-Bold.ttf"
            },
            {
                weight: 800,
                url: "https://videolocal.b-cdn.net/fonts/dm-sans/DMSans-ExtraBold.ttf"
            },
            {
                weight: 900,
                url: "https://videolocal.b-cdn.net/fonts/dm-sans/DMSans-Black.ttf"
            }
        ]
    },
    // lora
    {
        name: 'lora',
        availableWeights: [400, 500, 600, 700],
        links: [
            {
                weight: 400,
                url: "https://videolocal.b-cdn.net/fonts/lora/Lora-Regular.ttf"
            },
            {
                weight: 500,
                url: "https://videolocal.b-cdn.net/fonts/lora/Lora-Medium.ttf"
            },
            {
                weight: 600,
                url: "https://videolocal.b-cdn.net/fonts/lora/Lora-SemiBold.ttf"
            },
            {
                weight: 700,
                url: "https://videolocal.b-cdn.net/fonts/lora/Lora-Bold.ttf"
            }
        ]
    },
    // cinzel
    {
        name : 'cinzel',
        availableWeights: [400, 500, 600, 700, 800,900],
        links : [
            {
                weight: 400,
                url: "https://videolocal.b-cdn.net/fonts/cinzel/Cinzel-Regular.ttf"
            },
            {
                weight: 500,
                url: "https://videolocal.b-cdn.net/fonts/cinzel/Cinzel-Medium.ttf"
            },
            {
                weight: 600,
                url: "https://videolocal.b-cdn.net/fonts/cinzel/Cinzel-SemiBold.ttf"
            },
            {
                weight: 700,
                url: "https://videolocal.b-cdn.net/fonts/cinzel/Cinzel-Bold.ttf"
            },
            {
                weight: 800,
                url: "https://videolocal.b-cdn.net/fonts/cinzel/Cinzel-ExtraBold.ttf"
            },
            {
                weight: 900,
                url: "https://videolocal.b-cdn.net/fonts/cinzel/Cinzel-Black.ttf"
            }
        ]
    },
    // lato
    {
        name : 'lato',
        availableWeights: [100, 300, 400, 700, 900],
        links : [
            {
                weight: 100,
                url: "https://videolocal.b-cdn.net/fonts/lato/Lato-Thin.ttf"
            },
            {
                weight: 300,
                url: "https://videolocal.b-cdn.net/fonts/lato/Lato-Light.ttf"
            },
            {
                weight: 400,
                url: "https://videolocal.b-cdn.net/fonts/lato/Lato-Regular.ttf"
            },
            {
                weight: 700,
                url: "https://videolocal.b-cdn.net/fonts/lato/Lato-Bold.ttf"
            },
            {
                weight: 900,
                url: "https://videolocal.b-cdn.net/fonts/lato/Lato-Black.ttf"
            }
        ]
    },
    // comic neue
    {
        name : 'comic neue',
        availableWeights: [300,400,700],
        links : [
            {
                weight: 300,
                url: "https://videolocal.b-cdn.net/fonts/comic-neue/ComicNeue-Light.ttf"
            },
            {
                weight: 400,
                url: "https://videolocal.b-cdn.net/fonts/comic-neue/ComicNeue-Regular.ttf"
            },
            {
                weight: 700,
                url: "https://videolocal.b-cdn.net/fonts/comic-neue/ComicNeue-Bold.ttf"
            }
        ]
    },
    // merienda
    {
        name : 'merienda',
        availableWeights: [300,400,500,600,700,800,900],
        links : [
            {
                weight: 300,
                url: "https://videolocal.b-cdn.net/fonts/merienda/Merienda-Light.ttf"
            },
            {
                weight: 400,
                url: "https://videolocal.b-cdn.net/fonts/merienda/Merienda-Regular.ttf"
            },
            {
                weight: 500,
                url: "https://videolocal.b-cdn.net/fonts/merienda/Merienda-Medium.ttf"
            },
            {
                weight: 600,
                url: "https://videolocal.b-cdn.net/fonts/merienda/Merienda-SemiBold.ttf"
            },
            {
                weight: 700,
                url: "https://videolocal.b-cdn.net/fonts/merienda/Merienda-Bold.ttf"
            },
            {
                weight: 800,
                url: "https://videolocal.b-cdn.net/fonts/merienda/Merienda-ExtraBold.ttf"
            },
            {
                weight: 900,
                url: "https://videolocal.b-cdn.net/fonts/merienda/Merienda-Black.ttf"
            }
        ]
    }
]