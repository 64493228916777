import React, { useEffect, useRef, useState } from 'react'
import plus from "../../assets/icons/plus.svg"
import upload_icon from "../../assets/icons/upload.svg"
import ExcelJS from "exceljs";
import { useStore } from '../../utility/hooks/UseStore';
import droparrow from "../../assets/dropdown-arrow.svg";
import toast from 'react-hot-toast';
import APICallLoader from '../../components/ui/common/APILoaderRing'

function ImportDataModal({ setImportDataModal, id, setApiCallLoader , setExcelDataListFromStore }) {

    const [selectedOption, setSelectedOption] = useState("excel");
    const [fileName, setFileName] = useState("");
    const [processSection, setProcessSection] = useState('selectFile');


    // for show data in table format after upload
    const [data, setData] = useState([]);
    const [headers, setHeaders] = useState([]);
    const fileinputRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);

    // upload file or enter api endpoint to import data section

    const handleFileSelect = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            setFileName(file.name); // Store the uploaded file name

            if (file) {
                const fileExtension = file.name.split(".").pop();

                const validateHeaders = (headers) => {
                    // Reject headers that are only numbers or contain duplicates
                    const trimmedHeaders = headers?.map((header) => String(header).trim());
                    const uniqueHeaders = new Set(trimmedHeaders);
                    return {
                        hasNumberHeaders: trimmedHeaders.some((header) => /^\d+$/.test(header)),
                        hasDuplicates: uniqueHeaders.size !== trimmedHeaders.length,
                    };
                };

                if (fileExtension === "csv") {
                    const reader = new FileReader();
                    reader.onload = (event) => {
                        const text = event.target.result;

                        // Split the text by line breaks to get rows
                        const rows = text?.split("\n")?.filter((row) => row?.trim() !== "");

                        if (!rows || rows.length === 0) {
                            toast.error("Invalid file: The file is empty. Please upload a valid file.");
                            resetFileInput();
                            return;
                        }

                        // Extract headers from the first row
                        const headerRow = rows[0]?.split(",") || [];
                        const { hasNumberHeaders, hasDuplicates } = validateHeaders(headerRow);

                        // Validate headers
                        if (hasNumberHeaders) {
                            toast.error(
                                "Invalid file: Headers should not contain only numbers. Please upload a valid file."
                            );
                            resetFileInput();
                            return;
                        }
                        if (hasDuplicates) {
                            toast.error(
                                "Invalid file: Duplicate headers found. Please upload a valid file."
                            );
                            resetFileInput();
                            return;
                        }

                        if (headerRow?.length === 0) {
                            toast.error("Invalid file: Headers are missing. Please upload a valid file.");
                            resetFileInput();
                            return;
                        }

                        // Extract data rows
                        const dataRows = rows.slice(1)?.map((row) => {
                            const values = row.split(",");
                            const rowData = {};
                            headerRow.forEach((header, index) => {
                                rowData[String(header).trim()] = values[index]
                                    ? values[index].trim()
                                    : "";
                            });
                            return rowData;
                        });

                        if (!dataRows || dataRows?.length === 0) {
                            toast.error("Invalid file: The file contains headers but no data rows.");
                            resetFileInput();
                            return;
                        }

                        // Show loader for 0.5 second
                        showLoader(() => {
                            setProcessSection("headerSelection");
                            setHeaders(headerRow);
                            setData(dataRows);
                        });
                    };
                    reader.readAsText(file);
                } else if (fileExtension === "xlsx" || fileExtension === "xls") {
                    // Parse Excel using ExcelJS
                    const workbook = new ExcelJS.Workbook();
                    const reader = new FileReader();

                    reader.onload = async (event) => {
                        const arrayBuffer = event.target.result;
                        await workbook.xlsx.load(arrayBuffer);

                        const sheet = workbook.worksheets[0]; // Get the first worksheet
                        const cols = [];

                        // Extract headers
                        sheet.getRow(1).eachCell((cell) => {
                            cols.push(cell.value);
                        });

                        if (!cols || cols?.length === 0) {
                            toast.error("Invalid file: Headers are missing. Please upload a valid file.");
                            resetFileInput();
                            return;
                        }

                        const { hasNumberHeaders, hasDuplicates } = validateHeaders(cols);

                        // Validate headers
                        if (hasNumberHeaders) {
                            toast.error(
                                "Invalid file: Headers should not contain only numbers. Please upload a valid file."
                            );
                            resetFileInput();
                            return;
                        }
                        if (hasDuplicates) {
                            toast.error(
                                "Invalid file: Duplicate headers found. Please upload a valid file."
                            );
                            resetFileInput();
                            return;
                        }

                        const rows = [];
                        sheet.eachRow((row, rowIndex) => {
                            if (rowIndex === 1) return; // Skip header row
                            const rowData = {};
                            row.eachCell((cell, colIndex) => {
                                const header = cols[colIndex - 1];
                                if (cell.type === ExcelJS.ValueType.Date) {
                                    // Format date as needed, e.g., "YYYY-MM-DD"
                                    rowData[String(header).trim()] = cell.value
                                        ? cell.value.toISOString().split("T")[0]
                                        : null;
                                } else {
                                    rowData[String(header).trim()] = cell.value;
                                }
                            });
                            rows.push(rowData);
                        });

                        if (!rows || rows?.length === 0) {
                            toast.error("Invalid file: The file contains headers but no data rows.");
                            resetFileInput();
                            return;
                        }

                        // Show loader for 0.5 second
                        showLoader(() => {
                            setHeaders(cols);
                            setProcessSection("headerSelection");
                            setData(rows);
                        });
                    };

                    reader.readAsArrayBuffer(file);
                } else {
                    toast.error("Unsupported file type. Please upload a CSV or Excel file.");
                }
            }
        }

        const resetFileInput = () => {
            setFileName([]);
            if (fileinputRef.current) {
                fileinputRef.current.value = ""; // Reset the input using the ref
            }
        };
        const showLoader = (callback) => {
            setIsLoading(true); // Show loader
            setTimeout(() => {
                setIsLoading(false); // Hide loader
                callback();
            }, 500); // Delay for 0.5 second
        };
    };

    // Automatically select matching option
    const autoSelect = () => {
        setFields((prevFields) =>
            prevFields?.map((field) => {
                // Perform case-insensitive matching between headers and field names
                const isMatch = headers?.some(
                    (header) =>
                        header === field?.name
                );
                if (isMatch) {
                    return { ...field, value: field.name }; // Auto-select matching option
                }
                return field; // Leave unchanged if no match
            })
        );
    };

    const store = useStore().videoStore;
    const variables = store?.singleCampaignData?.headers;
    const [fields, setFields] = useState(variables); // Initial fields

    const [error, setError] = useState(null);

    // Handle changes in select dropdown
    const handleSelectChange = (id, newValue) => {
        const isDuplicate = fields?.some((field) => field.value === newValue);
        if (isDuplicate) {
            setError(`Error: "${newValue}" is already selected.`);
            return;
        }

        setError(null); // Clear any previous error
        setFields((prevFields) =>
            prevFields?.map((field) =>
                field.id === id ? { ...field, value: newValue } : field
            )
        );

    };

    useEffect(() => {
        if (processSection === 'mappingData') {
            autoSelect(); // Auto-select on initial render
        }
    }, [processSection]);

    // mapping validation

    const validateMapping = () => {
        const hasUnmappedFields = fields?.some((field) => !field.value);
        if (hasUnmappedFields) {
            setError('Please map all columns before proceeding.');
            return false;
        }
        setError('')
        return true;
    };

    const handleReviewDataClick = () => {
        if (validateMapping()) {
            setProcessSection('ReviewData');
        }
    };

    // upload file or enter api endpoint to import data section

    const renderContent = () => {
        switch (selectedOption) {
            case "excel":
            case "csv":
                return (
                    <div className="relative w-full">
                        <label
                            htmlFor="fileUpload"
                            className="flex flex-col items-center w-full justify-center h-40 cursor-pointer border border-dashed border-[#ababc0] bg-[#3B3B4F] rounded transition-all"
                        >
                            {fileName && fileName?.length > 0 ? (
                                <p className="text-[#fff] font-Inter">Uploaded File: {fileName}</p>
                            ) : (
                                <>
                                    <img src={upload_icon} alt='upload_icon' className=' mb-2'></img>
                                    <h3 className="font-Inter font-medium text-sm text-[#FFFFFF] my-[5px]">Upload a file</h3>
                                    <span className="block font-Inter font-normal text-sm text-[#ABABC0]">Drag & drop or browse to choose a file here</span>
                                </>
                            )}
                        </label>
                        <input
                            type="file"
                            id="fileUpload"
                            accept={".xlsx,.xls,.csv"}
                            onChange={handleFileSelect}
                            ref={fileinputRef}
                            className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                        />
                    </div>
                );
            case "api":
                return (
                    <div >
                        <label htmlFor="apiEndpoint" className="block mb-2 text-[#ababc0] text-[14px] font-medium font-Inter">
                            Enter API Endpoint:
                        </label>
                        <div className=' flex items-center justify-between'>
                            <input
                                type="text"
                                id="apiEndpoint"
                                placeholder="https://example.com/api"
                                className="w-full px-3 py-2  rounded-md bg-[#3B3B4F] text-[#fff] outline-none"
                            />
                            <button className="bg-[#5446D0] ml-3 font-Inter font-medium text-[14px] py-[9px] 2xl:py-[8px] px-[12px] rounded-[4px] text-[#FFFFFF] cursor-pointer outline-none">
                                Import
                            </button>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    // The render method for your mapping data section

    const renderMappingSection = () => (
        <div className='m-5 min-h-[400px] max-h-[400px] overflow-auto scrollbar-thumb-[#5446D0] scrollbar-thin scrollbar-track-transparent'>
            <div className=' w-[50%] relative'>
                <div className="flex items-center mb-4">
                    <p className="w-1/2 text-[14px] font-Inter">Variable</p>
                    <p className="w-1/2 text-[14px] font-Inter">Column in your sheet</p>
                </div>

                {fields?.map((field) => (
                    <div key={field.id} className="flex items-center border border-[#3b3b4f] rounded-lg shadow-sm my-2 overflow-hidden h-10">
                        {/* Left Section */}
                        <div className="flex-1 relative bg-[#3B3B4F] px-4 py-2 rounded-l-lg text-white">
                            <p className="font-medium text-[14px]">{field.name}</p>
                            <div className="border-shape z-10"></div>
                        </div>

                        {/* Right Section */}
                        <div className="flex-1 text-right">
                            <div className=' relative'>
                                <select
                                    className="w-full px-7 py-2 border-none cursor-pointer rounded-md text-sm bg-[#1e1e29] outline-none"
                                    value={field.value}
                                    onChange={(e) => handleSelectChange(field.id, e.target.value)}
                                >
                                    <option value="">Select Column</option>
                                    {headers?.map((option) => (
                                        <option key={option} value={option} className='text-[#fff]'>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                                <img src={droparrow} alt="DropdownArrow" className={` absolute right-[15px] top-[13px] transition-all  ml-2 mt-[3px]`}></img>
                            </div>
                        </div>
                    </div>
                ))}

                {/* Display Error */}
                {error && (
                    <div className="text-[12px] leading-[14px] absolute bottom-[-25px]  font-medium text-[#FF5364]">
                        {error}
                    </div>
                )}
            </div>
            <div className='flex items-center justify-end  absolute right-[20px] bottom-0'>
                <button onClick={() => { UploadAgainFile() }} className="border border-[#5446D0] my-[16px] mr-3 font-Inter font-medium text-[14px] py-[9px] 2xl:py-[8px] px-[12px] rounded-[4px] text-[#FFFFFF] cursor-pointer outline-none">
                    Upload Again
                </button>
                <button
                    onClick={() => handleReviewDataClick()}
                    className="bg-[#5446D0] my-[16px] font-Inter font-medium text-[14px] py-[9px] 2xl:py-[8px] px-[12px] rounded-[4px] text-[#FFFFFF] cursor-pointer outline-none"
                >
                    Review Data
                </button>
            </div>
        </div>
    );

    // Render the table for review

    const renderReviewTable = () => {
        if (data?.length === 0 || fields?.length === 0) return null;

        // Create rows based on the column mapping
        const rows = data?.map((row, rowIndex) => {
            return (
                <tr key={rowIndex}>
                    {fields?.map((field) => {
                        // const columnName = columnMapping[field.id];
                        return (
                            <td key={field.id} className="border text-[14px] border-[#3b3b4f] px-4 py-2">
                                {row[field.value] || ""}
                            </td>
                        );
                    })}
                </tr>
            );
        });

        return (
            <div >
                <div className='max-h-[400px] mx-[20px] min-h-[400px] overflow-auto scrollbar-thumb-[#5446D0] scrollbar-thin scrollbar-track-transparent'>
                    <table className="table-auto border-collapse border  border-[#3b3b4f] w-full">
                        <thead className='bg-[#1e1e29] sticky top-[-1px] z-50'>
                            <tr>
                                {fields.map((field) => (
                                    <th key={field.id} className="border border-[#3b3b4f] text-[14px] text-left px-4 py-2">
                                        {field.name}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>{rows}</tbody>
                    </table>
                </div>
                <div className='flex items-center justify-end mr-[20px]'>
                    <button onClick={() => { UploadAgainFile() }} className="border border-[#5446D0] my-[16px] mr-3 font-Inter font-medium text-[14px] py-[9px] 2xl:py-[8px] px-[12px] rounded-[4px] text-[#FFFFFF] cursor-pointer outline-none">
                        Upload Again
                    </button>
                    <button
                        onClick={() => completeImport()}
                        className="bg-[#5446D0] my-[16px] font-Inter font-medium text-[14px] py-[9px] 2xl:py-[8px] px-[12px] rounded-[4px] text-[#FFFFFF] cursor-pointer outline-none"
                    >
                        Complete
                    </button>
                </div>
            </div>
        );
    };

    // complete import data

    const completeImport = () => {
        // Generate CSV content
        let csvContent = '';

        // Add header row (mapped fields only)
        const mappedFields = fields?.filter((field) => field.value);
        csvContent += mappedFields?.map((field) => field.name).join(',') + '\n';

        // Add data rows (mapped fields only)
        data.forEach((row) => {
            const rowValues = mappedFields?.map((field) => row[field.value] || '');
            csvContent += rowValues.join(',') + '\n';
        });

        // Create a Blob from the CSV content
        const blob = new Blob([csvContent], { type: 'text/csv' });

        // Create a File object (optional, but useful for naming the file)
        const file = new File([blob], 'mapped_data.csv', { type: 'text/csv' });

        // Prepare the FormData
        const formData = new FormData();
        formData.append('dataFile', file);
        formData.append("source", 'excel');
        store.importDataComplete({ campaignID: id, formdata: formData, loader: setApiCallLoader, setImportDataModal , setExcelDataListFromStore })
    }

    // data process steps

    const steps = [
        { id: 1, name: 'Confirm Data', section: 'headerSelection' },
        { id: 2, name: 'Map Columns', section: 'mappingData' },
        { id: 3, name: 'Review', section: 'ReviewData' }
    ]

    const [isOpen, setIsOpen] = useState(false);

    const toggleDownload = () => {
        setIsOpen(!isOpen);
    };

    // Download Sample File
    const downloadSample = (type) => {
        store.downloadSampleFile({ campaignID: id, type: type, loader: setApiCallLoader })
    }

    // upload again button click

    const UploadAgainFile = () => {
        setProcessSection('selectFile');
        setFileName([]);
        setFields(variables);
        setError(null);
        setData([]);
    }

    return (
        <div>
            {/* API Loader */}
            <div className={`${isLoading ? 'flex' : 'hidden'} fixed top-0 left-0 overflow-hidden justify-center items-center z-[9999]`}>
                <APICallLoader />
            </div>
            <div className="fixed top-0 z-[50] left-0 right-0 bottom-0 w-full bg-black bg-opacity-50">
                <div className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#272836] rounded-md font-Inter text-[#FFFFFF] ${processSection === 'selectFile' ? 'w-[500px]' : 'w-[90%] min-h-[535px]'} `}>
                    <div className=' flex items-center border-b border-[#3b3b4f] justify-between px-[20px] py-[16px]'>
                        <h1 className="text-[18px] font-medium font-Inter">Import Data</h1>
                        {processSection !== 'selectFile' && <div className='flex gap-7'>
                            {steps?.map((step, index) => {
                                const isCompleted = steps.findIndex((s) => s.section === processSection) > index;
                                return (<div key={index} className={`flex items-center gap-2`}>
                                    {isCompleted ? <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_7902_2030)">
                                            <path d="M11.5 21.375C17.1954 21.375 21.8124 16.7579 21.8124 11.0625C21.8124 5.36712 17.1954 0.750065 11.5 0.750065C5.80456 0.750065 1.1875 5.36712 1.1875 11.0625C1.1875 16.7579 5.80456 21.375 11.5 21.375Z" stroke="#B46CFF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M16.2834 7.71061L9.99404 14L7 11.006" stroke="#B46CFF" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_7902_2030">
                                                <rect width="22.1249" height="22.1249" fill="white" transform="translate(0.4375)" />
                                            </clipPath>
                                        </defs>
                                    </svg> : <p className={`${processSection === step.section ? 'border border-[#B46CFF]' : ''} min-w-[30px] min-h-[30px] bg-[#3b3b4f] flex items-center justify-center text-[14px] py-[2px] rounded-full`}>{step.id}</p>}
                                    <p className={`${processSection === step.section ? 'text-[#fff]' : 'text-[#ababc0]'}  text-[14px]`}>{step.name}</p>
                                </div>
                                )
                            })}
                        </div>}
                        <img src={plus} className=' cursor-pointer rotate-45' onClick={() => setImportDataModal(false)}></img>
                    </div>

                    {/* upload file or enter api endpoint to import data  */}

                    {processSection === 'selectFile' &&
                        <div className="p-[20px]">
                            <div className="mb-4 flex items-center justify-between">
                                <div className="flex gap-2">
                                    <label className={`flex items-center mr-[15px] text-[14px] ${selectedOption === 'excel' ? 'text-[#fff]' : 'text-[#ababc0]'} font-medium font-Inter cursor-pointer`}>
                                        <input
                                            type="radio"
                                            value="excel"
                                            checked={selectedOption === "excel"}
                                            onChange={(e) => setSelectedOption(e.target.value)}
                                            className="mr-2"
                                        />
                                        Excel / CSV
                                    </label>
                                    <label className={`flex items-center text-[14px] ${selectedOption === 'api' ? 'text-[#fff]' : 'text-[#ababc0]'} font-medium font-Inter cursor-pointer`}>
                                        <input
                                            type="radio"
                                            value="api"
                                            checked={selectedOption === "api"}
                                            onChange={(e) => setSelectedOption(e.target.value)}
                                            className="mr-2"
                                        />
                                        API
                                    </label>
                                </div>
                                <div className="relative ">
                                    <button
                                        onClick={toggleDownload}
                                        className="bg-[#3b3b4f] font-Inter font-medium text-[14px] py-[10px] 2xl:py-[8px] px-[12px] rounded-[4px] text-[#FFFFFF] cursor-pointer outline-none flex items-center"
                                    >
                                        Download sample
                                        <img src={droparrow} alt="DropdownArrow" className={` transition-all ${!isOpen ? 'rotate-0' : 'rotate-180'} ml-2 mt-[3px]`}></img>
                                    </button>
                                    {isOpen && (
                                        <div className="absolute right-0 mt-2 w-28 bg-[#1e1e29] rounded-md overflow-hidden shadow-xl z-10">
                                            <ul className=" text-[#ababc0]">
                                                <li>
                                                    <button
                                                        onClick={() => downloadSample('csv')}
                                                        className="block w-full text-[14px] text-left px-4 py-1 hover:text-[#fff] hover:bg-[#5446d0]"
                                                    >
                                                        CSV
                                                    </button>
                                                </li>
                                                <li>
                                                    <button
                                                        onClick={() => downloadSample('xlsx')}
                                                        className="block w-full text-[14px] text-left px-4 py-1 hover:text-[#fff] hover:bg-[#5446d0]"
                                                    >
                                                        Excel
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div>{renderContent()}</div>
                        </div>}

                    {/* show data in table format after upload */}
                    {processSection === 'headerSelection' &&
                        <div className='w-full'>
                            {headers?.length > 0 && (
                                <div className='min-h-[400px] mx-[20px] max-h-[400px] overflow-auto scrollbar-thumb-[#5446D0] scrollbar-thin scrollbar-track-transparent'>
                                    <table className="table-auto border-collapse border  border-[#3b3b4f] w-full">
                                        <thead className='bg-[#1e1e29] sticky top-[-1px] z-50'>
                                            <tr>
                                                {headers?.map((header, index) => (
                                                    <th key={index} className="border border-[#3b3b4f] text-[14px] text-left px-4 py-2">
                                                        {header}
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data?.map((row, rowIndex) => (
                                                <tr key={rowIndex}>
                                                    {headers?.map((header, colIndex) => (
                                                        <td key={colIndex} className="border text-[14px] border-[#3b3b4f] px-4 py-2">
                                                            {row[header] || ""}
                                                        </td>
                                                    ))}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                            <button onClick={() => setProcessSection('mappingData')} className="bg-[#5446D0] my-[16px] mr-[20px] flex justify-end ml-auto  font-Inter font-medium text-[14px] py-[9px] 2xl:py-[8px] px-[12px] rounded-[4px] text-[#FFFFFF] cursor-pointer outline-none">
                                Confirm and Continue
                            </button>
                        </div>}

                    {/* mapping data section */}

                    {processSection === 'mappingData' && renderMappingSection()}

                    {/* review data section */}

                    {processSection === 'ReviewData' && renderReviewTable()}

                </div>
            </div>
        </div>
    )
}

export default ImportDataModal