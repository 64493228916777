import React, { useEffect, useState } from 'react'
import rotate_icon from '../../../../assets/rotate_icon.png'
import animation_icon from "../../../../assets/icons/animation.svg";
import scale_x_icon from "../../../../assets/scale_x.png";
import scale_y_icon from "../../../../assets/scale_y.png";
import opacity_icon from "../../../../assets/icons/opacity.svg";
import { useStore } from '../../../../utility/hooks/UseStore';
import toast from 'react-hot-toast';

const ImageEditPanel = ({imageData}) => {

    const mainStore = useStore();
    const store = mainStore.videoStore;

    const selectedElement = store.selectedElement || {}
    const editorElement = selectedElement?.id

    const [width, setWidth] = useState()
    const [height, setHeight] = useState()
    const [xPosition, setXPosition] = useState()
    const [yPosition, setYPosition] = useState()
    const [scaleX, setScaleX] = useState()
    const [scaleY, setScaleY] = useState()
    const [opacity, setOpacity] = useState()
    const [rotate, setRotate] = useState()

    const handleWidthChange = (e) => {
        const newWidth = e.target.value;
        if (e.target.value > 100) {
            e.target.value = 100;  // Prevent value from going over 100
        }else{
             setWidth(newWidth)
        if (editorElement) {
            store.updateImageWidth(editorElement, newWidth)
        }
        }
       
    }

    // const handleHeightChange = () => {
    //     const newHeight = e.target.value;
    //     setHeight(newHeight)
    //     if(editorElement){
    //         store.updateImageHeight(editorElement, newHeight)
    //     }
    // }

    const handleXpositionChange = (e) => {
        const newPosition = e.target.value
        setXPosition(newPosition)
        if (editorElement) {
            store.updateImageXposition(editorElement, newPosition)
        }
    }

    const handleYpositionChange = (e) => {
        const newPosition = e.target.value
        setYPosition(newPosition)
        if (editorElement) {
            store.updateImageYposition(editorElement, newPosition)
        }
    }

    const handleScaleXchange = (e) => {
        const newScale = e.target.value
        setScaleX(newScale)
        if (editorElement) {
            store.updateImageScaleX(editorElement, newScale)
        }
    }

    const handleScaleYchange = (e) => {
        const newScale = e.target.value
        setScaleY(newScale)
        if (editorElement) {
            store.updateImageScaleY(editorElement, newScale)
        }
    }

    const handleRotateChange = (e) => {
        const newRotation = e.target.value
        if (e.target.value > 360) {
            e.target.value = 360;
        }else if(e.target.value < 0){
            e.target.value = 0;
        }else{
        setRotate(newRotation)
        if (editorElement) {
            store.updateImageRotate(editorElement, newRotation)
        }
    }
    }

    const handleOpacityChange = (e) => {
        const newOpacity = parseInt(e.target.value)
        if (e.target.value > 100) {
            e.target.value = 100;  // Prevent value from going over 100
        }else{
        setOpacity(newOpacity)
        if(editorElement){
            store.updateImageOpacity(editorElement, newOpacity / 100)
        }
    }
    }

    // this is all logic for converting milisecond and handle input text
    const [startTimeValue, setStartTimeValue] = useState({
        min: 0,
        sec: 0,
        miliSec: 0
    })
    const [endTimeValue, setEndTimeValue] = useState({
        min: 0,
        sec: 0,
        miliSec: 0
    })


    useEffect(() => {

        if (store.selectedElement && store.selectedElement.type === 'image' && imageData) {
            const element = store.selectedElement
            setWidth(imageData.placement?.width)
            setHeight(imageData.placement?.height)
            setXPosition(imageData.placement.x)
            setYPosition(imageData.placement.y)
            setScaleX(parseFloat(imageData.placement?.scaleX).toFixed(2));
            setScaleY(parseFloat(imageData.placement?.scaleY).toFixed(2));
            setXPosition(parseFloat(imageData.placement?.x)?.toFixed(2))
            setYPosition(parseFloat(imageData.placement?.y)?.toFixed(2))
            setRotate(parseFloat(imageData.placement.rotation)?.toFixed(2))
            setOpacity(imageData.properties.opacity * 100)
        }
    }, [store.selectedElement , imageData])


    useEffect(() => {
        const startTime = selectedElement?.timeFrame?.start
        const startMin = Math.floor(startTime / 60000);
        const startSec = Math.floor((startTime - startMin * 60000) / 1000);
        const startMiliSec = startTime - startMin * 60000 - startSec * 1000;
        setStartTimeValue({
            min: startMin,
            sec: startSec,
            miliSec: startMiliSec,
        })
        const endTime = selectedElement?.timeFrame?.end;
        const endMin = Math.floor(endTime / 60000);
        const endSec = Math.floor((endTime - endMin * 60000) / 1000);
        const endMiliSec = endTime - endMin * 60000 - endSec * 1000;
        setEndTimeValue({
            min: endMin,
            sec: endSec,
            miliSec: endMiliSec,
        })
    }, [selectedElement?.timeFrame?.start, selectedElement?.timeFrame?.end])

    const handleTimeChange = (e, from) => {
        if (from === "start") {
            const { name, value } = e.target;

            const tempState = {
                min: startTimeValue.min,
                sec: startTimeValue.sec,
                miliSec: startTimeValue.miliSec,
            };
            tempState[name] = value;

            const min = Number(tempState.min);
            const sec = Number(tempState.sec);
            const miliSec = Number(tempState.miliSec);
            const total_milliseconds =
                Number(min * 60 * 1000) + Number(sec * 1000) + Number(miliSec);

            if (
                Number(total_milliseconds) >= Number(store.maxTime) ||
                Number(total_milliseconds) < 0 ||
                Number(total_milliseconds) >=
                Number(store.selectedElement.timeFrame.end)
            ) {
                toast.error(
                    "Time should be less than video duration and not should be negative nor greater than end time"
                );
                return;
            } else {
                store.updateStartEndTime(
                    editorElement,
                    "start",
                    Number(total_milliseconds)
                );
                setStartTimeValue({
                    ...tempState,
                });
            }
        } else if (from === "end") {
            const { name, value } = e.target;

            const tempState = {
                min: endTimeValue.min,
                sec: endTimeValue.sec,
                miliSec: endTimeValue.miliSec,
            };
            tempState[name] = value;

            const min = Number(tempState.min);
            const sec = Number(tempState.sec);
            const miliSec = Number(tempState.miliSec);

            const total_milliseconds =
                Number(min * 60 * 1000) + Number(sec * 1000) + Number(miliSec);

            if (
                Number(total_milliseconds) > Number(store.maxTime) ||
                Number(total_milliseconds) <
                Number(store.selectedElement.timeFrame.start)
            ) {
                toast.error("Time should be less than video duration");
                return;
            } else {
                store.updateStartEndTime(editorElement, "end", total_milliseconds);
                setEndTimeValue({
                    ...tempState,
                });
            }
        }
    };


    return (
        <div className='p-[10px] 2xl:p-[20px]'>
            <div className=' grid grid-cols-12 gap-[5px] pb-[20px] 2xl:gap-[10px]'>
                <p className=' col-span-8 text-[14px] font-Inter font-medium text-[#ababc0]'>Position</p>
                {/* <div className="col-span-6 py-[8px] px-[16px] flex bg-[#3B3B4F] group rounded relative">
                    <p className=' text-[16px] text-[#ababc0] w-[22px] '>W</p>
                    <span className="tooltip hidden group-hover:block bg-[#000] text-[#fff] min-w-[80px] top-[45px] z-[20] left-[5px] text-[12px] rounded-[6px] p-[5px] absolute text-center">Width</span>
                    <input type="number"
                        min={0}
                        step={0.01}
                        value={width}
                        onChange={handleWidthChange}
                        className="text-center  w-full text-[14px] focus:outline-none placeholder-white bg-transparent text-white"
                        placeholder="0"
                    />
                </div> */}
                {/* <div className="col-span-6 py-[8px] px-[16px] flex bg-[#3B3B4F] rounded">
                    <p className=' text-[16px] text-[#ababc0] w-[22px]'>H</p>
                    <input
                        type="number"
                        min={0}
                        step={0.01}
                        value={height}
                        // onChange={handlebtnWidthChange}
                        className="text-center  w-full text-[14px] focus:outline-none placeholder-white bg-transparent text-white"
                        placeholder="0"
                    />
                </div> */}
                <div className="col-span-6 py-[8px] px-[16px] flex bg-[#3B3B4F] group rounded relative">
                    <p className=' text-[16px] text-[#ababc0] w-[22px]'>X</p>
                    <span className="tooltip hidden group-hover:block bg-[#000] text-[#fff] min-w-[80px] top-[45px] z-[20] left-[5px] text-[12px] rounded-[6px] p-[5px] absolute text-center">Position X</span>
                    <input
                        type="number"
                        min={0}
                        step={0.01}
                        value={Number(xPosition)}
                        onChange={handleXpositionChange}
                        className="text-center  w-full text-[14px] focus:outline-none placeholder-white bg-transparent text-white"
                        placeholder="0"
                    />
                </div>
                <div className="col-span-6 py-[8px] px-[16px] flex bg-[#3B3B4F] group relative rounded">
                    <p className=' text-[16px] text-[#ababc0] w-[22px]'>Y</p>
                    <span className="tooltip hidden group-hover:block bg-[#000] text-[#fff] min-w-[80px] top-[45px] z-[20] left-[5px] text-[12px] rounded-[6px] p-[5px] absolute text-center">Position Y</span>
                    <input
                        type="number"
                        min={0}
                        step={0.01}
                        value={Number(yPosition)}
                        onChange={handleYpositionChange}
                        className="text-center  w-full text-[14px] focus:outline-none placeholder-white bg-transparent text-white"
                        placeholder="0"
                    />
                </div>
                <div className="col-span-6 py-[8px] px-[12px] flex bg-[#3B3B4F] group relative rounded">
                    <img
                        src={scale_x_icon}
                        alt="Radius"
                        className=" w-[20px] h-auto object-contain "
                    />
                    <span className="tooltip hidden group-hover:block bg-[#000] text-[#fff] min-w-[80px] top-[45px] z-[20] left-[5px] text-[12px] rounded-[6px] p-[5px] absolute text-center">Scale X</span>
                    <input
                        type="number"
                        min={0}
                        step={0.01}
                        value={scaleX}
                        onChange={handleScaleXchange}
                        className="text-center  w-full text-[14px] focus:outline-none placeholder-white bg-transparent text-white"
                        placeholder="0"
                    />
                </div>
                <div className="col-span-6 py-[8px] px-[12px] flex bg-[#3B3B4F] group relative rounded">
                    <img
                        src={scale_y_icon}
                        alt="Radius"
                        className=" w-[20px] h-auto object-contain "
                    />
                    <span className="tooltip hidden group-hover:block bg-[#000] text-[#fff] min-w-[80px] top-[45px] z-[20] left-[5px] text-[12px] rounded-[6px] p-[5px] absolute text-center">Scale Y</span>
                    <input
                        type="number"
                        min={0}
                        step={0.01}
                        value={scaleY}
                        onChange={handleScaleYchange}
                        className="text-center  w-full text-[14px] focus:outline-none placeholder-white bg-transparent text-white"
                        placeholder="0"
                    />
                </div>
                <div className="col-span-6 py-[8px] px-[12px] relative group flex bg-[#3B3B4F] rounded">
                    <img
                        src={opacity_icon}
                        alt="Radius"
                        className=" w-[18px]"
                    />
                    <span className="tooltip hidden group-hover:block bg-[#000] text-[#fff] min-w-[80px] top-[45px] z-[20] left-[5px] text-[12px] rounded-[6px] p-[5px] absolute text-center">Opacity</span>
                    <input
                        type="number"
                        min={0}
                        step={1}
                        value={parseInt(opacity)}
                        onChange={handleOpacityChange}
                        className="text-center  w-full text-[14px] focus:outline-none placeholder-white bg-transparent text-white"
                        placeholder="0"
                    />
                </div>
            </div>
            <div className=' grid grid-cols-12 gap-[5px] 2xl:gap-[10px] border-t border-[#3B3B4F] py-[20px]'>
                <p className=' col-span-8 text-[14px] font-Inter font-medium text-[#ababc0]'>Rotation</p>
                <div className="col-span-6 py-[8px] px-[12px] flex bg-[#3B3B4F] rounded">
                    <img src={rotate_icon} className=' w-[14px] h-[14px] self-center'></img>
                    <input
                        type="number"
                        min={0}
                        step={0.5}
                        max={360}
                        value={rotate}
                        onChange={handleRotateChange}
                        className="text-center  w-full text-[14px] focus:outline-none placeholder-white bg-transparent text-white"
                        placeholder="0"
                    />
                </div>
            </div>


            <div className=' grid grid-cols-12 border-y py-[20px] border-[#3B3B4F]'>
                <button
                    onClick={() => {
                        store.setSelectedMenuOption("Animation");
                    }}
                    className="col-span-12 bg-[#3B3B4F] p-[10px]  rounded flex justify-center items-center gap-[10px] outline-none"
                >
                    <img src={animation_icon} alt="animation" />
                    <span className="font-Inter font-normal text-sm text-[#FFFFFF]">
                        Animation
                    </span>
                </button>
            </div>


            <div className="pt-[20px]">
                <p className=" text-[#ABABC0] font-Inter font-medium text-[14px] pb-[8px] 2xl:pb-[16px]">
                    Timeline
                </p>
                <div className=" grid grid-cols-12 gap-[1px] 2xl:gap-[12px]">

                    <div className="col-span-12 flex items-end">
                        <label className="text-[14px] min-w-[35px] font-Inter self-center font-medium text-[#ABABC0]">
                            Start:
                        </label>
                        <div className="ml-2 2xl:ml-3">
                            <label className=" text-[11px] font-Inter font-medium text-[#ABABC0] block text-center">
                                Min
                            </label>
                            <div className="flex justify-start items-center">
                                <input
                                    onChange={(e) => {
                                        handleTimeChange(e, "start");
                                    }}
                                    name="min"
                                    type="number"
                                    value={Number(startTimeValue.min).toFixed()}
                                    className="w-[44px] text-center h-[38px] rounded-[4px] text-[14px] bg-[#3b3b4f] focus:outline-none placeholder-white text-white"
                                    placeholder="0"
                                />
                            </div>
                        </div>
                        <div className="ml-2 2xl:ml-3">
                            <label className=" text-[11px] font-Inter font-medium text-[#ABABC0] block text-center">
                                Sec
                            </label>
                            <div className="flex justify-start items-center">
                                <input
                                    onChange={(e) => {
                                        handleTimeChange(e, "start");
                                    }}
                                    name="sec"
                                    type="number"
                                    value={Number(startTimeValue.sec).toFixed()}
                                    className="w-[44px] text-center h-[38px] rounded-[4px] text-[14px] bg-[#3b3b4f] focus:outline-none placeholder-white text-white"
                                    placeholder="0"
                                />
                            </div>
                        </div>
                        <div className="ml-2 2xl:ml-3">
                            <label className=" text-[11px] font-Inter font-medium text-[#ABABC0] block text-center">
                                Mili Sec
                            </label>
                            <div className="flex justify-start items-center">
                                <input
                                    onChange={(e) => {
                                        handleTimeChange(e, "start");
                                    }}
                                    name="miliSec"
                                    type="number"
                                    value={Number(startTimeValue.miliSec).toFixed()}
                                    className="w-[44px] text-center h-[38px] rounded-[4px] text-[14px] bg-[#3b3b4f] focus:outline-none placeholder-white text-white"
                                    placeholder="0"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-span-12 flex items-end py-[10px]">
                        <label className=" text-[14px] min-w-[35px] font-Inter font-medium self-center text-[#ABABC0] ">
                            End:
                        </label>
                        <div className="ml-2 2xl:ml-3">
                            <label className=" text-[11px] font-Inter font-medium text-[#ABABC0] block text-center">
                                Min
                            </label>
                            <div className="flex justify-start items-center">
                                <input
                                    onChange={(e) => {
                                        handleTimeChange(e, "end");
                                    }}
                                    name="min"
                                    type="number"
                                    value={Number(endTimeValue.min).toFixed()}
                                    className="w-[44px] text-center h-[38px] rounded-[4px] text-[14px] bg-[#3b3b4f] focus:outline-none placeholder-white text-white"
                                    placeholder="0"
                                />
                            </div>
                        </div>
                        <div className="ml-2 2xl:ml-3">
                            <label className=" text-[11px] font-Inter font-medium text-[#ABABC0] block text-center">
                                Sec
                            </label>
                            <div className="flex justify-start items-center">
                                <input
                                    onChange={(e) => {
                                        handleTimeChange(e, "end");
                                    }}
                                    name="sec"
                                    type="number"
                                    value={Number(endTimeValue.sec).toFixed()}
                                    className="w-[44px] text-center h-[38px] rounded-[4px] text-[14px] bg-[#3b3b4f] focus:outline-none placeholder-white text-white"
                                    placeholder="0"
                                />
                            </div>
                        </div>
                        <div className="ml-2 2xl:ml-3">
                            <label className=" text-[11px] font-Inter font-medium text-[#ABABC0] block text-center">
                                Mili Sec
                            </label>
                            <div className="flex justify-start items-center">
                                <input
                                    onChange={(e) => {
                                        handleTimeChange(e, "end");
                                    }}
                                    name="miliSec"
                                    type="number"
                                    value={Number(endTimeValue.miliSec).toFixed()}
                                    className="w-[44px] text-center h-[38px] rounded-[4px] text-[14px] bg-[#3b3b4f] focus:outline-none placeholder-white text-white"
                                    placeholder="0"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ImageEditPanel