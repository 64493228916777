import React, { useEffect, useRef } from 'react';
import WaveSurfer from 'wavesurfer.js';

const Waveform = ({ audioUrl , element }) => {
  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);

  useEffect(() => {
    const controller = new AbortController();

    // Initialize WaveSurfer
    wavesurfer.current = WaveSurfer.create({
      container: waveformRef.current,
      waveColor: "#fff",
      progressColor: "#fff",
      cursorColor: "transparent",
      barWidth: 0.1,
      barHeight: element && element?.properties.engine === "openai" ? 0.6 :  0.3,
      height: window.innerWidth < 1280 ? 60 : 80,
      responsive: true,
    });

    // Load audio
    if(audioUrl){
        wavesurfer.current.load(audioUrl);
    }

    // Cleanup on component unmount
    return () => {
      controller.abort();
      wavesurfer.current.destroy();
    };
  }, [audioUrl]);

  return <div ref={waveformRef} style={{ width: '100%' }}></div>;
};

export default Waveform;
